import { HeadingLg } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { CtaButtonGhost } from '/features/buildingBlocks/CtaButton'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useParralax } from '/machinery/useParralax'
import { animated } from '@react-spring/web'

import styles from './CampaignBanner.css'

export function CampaignBanner({ title, text, images, linkPrimary, linkSecondary, video }) {
  const previewSources = video?.urls && {
    1080: video.urls.quality1080,
    720: video.urls.quality720,
    540: video.urls.quality540,
    360: video.urls.quality360,
  }

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        {title && <HeadingLg layoutClassName={styles.layoutHeading} h={3}>{title}</HeadingLg>}
        {text && <PortableTextMainContent value={text} />}

        <div className={styles.buttons}>
          {linkPrimary &&
            <CtaButtonGhost
              href={determineDocumentPathSync({ document: linkPrimary.ref, routeMap })}
              dataX='link'
            >
              {linkPrimary.label}
            </CtaButtonGhost>
          }
          {linkSecondary &&
            <CtaButtonGhost
              href={determineDocumentPathSync({ document: linkSecondary.ref, routeMap })}
              dataX='link'
            >
              {linkSecondary.label}
            </CtaButtonGhost>
          }
        </div>

      </div>
      <Grid {... { images, video }} sources={previewSources} />
    </div>
  )
}

function Grid({ images, video, sources }) {
  if (images?.length === 2 && !video?.url) {
    return <MultipleGridImages layoutClassName={styles.multipleGridLayout} {... { images }} />
  }
  if (images?.length === 1 && !video?.url) {
    return <SingleGridImage layoutClassName={styles.singleGridLayout} {... { images }} />
  }
  if ((video?.url || video?.urls) && images?.length >= 1) {
    return <MultipleGridImageAndVideo layoutClassName={styles.multipleGridLayout} {... { images, sources }} />
  }
  if ((!images || images?.length === 0) && (video?.url || video?.urls))
    return <SingleGridVideo layoutClassName={styles.singleGridVideoLayout} {... { sources }} />
}

function MultipleGridImageAndVideo({ sources, images, layoutClassName }) {
  return (
    <div className={cx(styles.componentMultipleGridImageAndVideo, layoutClassName)}>
      <Video layoutClassName={styles.video} {... { sources }} index={1} />
      <Image layoutClassName={cx(styles.image, styles.imageTwo)} index={0} image={images[0]} aspectRatio={2 / 3} />
    </div>
  )
}

function SingleGridVideo({ sources, layoutClassName }) {
  return (
    <div className={cx(styles.componentSingleGridVideo, layoutClassName)}>
      <Video index={0} {... { sources }} layoutClassName={styles.video} />
    </div>
  )
}

function SingleGridImage({ images, layoutClassName }) {
  return (
    <div className={cx(styles.componentSingleGridImage, layoutClassName)}>
      <Image layoutClassName={cx(styles.image, styles.imageSingleGrid)} index={0} image={images[0]} aspectRatio={1 / 1} />
    </div>
  )
}

function MultipleGridImages({ images, layoutClassName }) {
  return (
    <div className={cx(styles.componentMultipleGridImages, layoutClassName)}>
      <Image layoutClassName={cx(styles.image, styles.imageOne)} index={1} image={images[0]} aspectRatio={1 / 1} />
      <Image layoutClassName={cx(styles.image, styles.imageTwo)} index={0} image={images[1]} aspectRatio={2 / 3} />
    </div>
  )
}

function Video({ index, layoutClassName, sources }) {
  const animation = useParralax(index)
  const { translateY, divRef } = animation
  return (
    <animated.div className={layoutClassName} style={{ translateY }} ref={divRef}>
      <BackgroundVideo playing {... { sources }} />
    </animated.div>
  )
}

function Image({ image, index, aspectRatio, layoutClassName }) {
  const animation = useParralax(index)
  const { translateY, divRef } = animation
  return (
    <animated.div className={layoutClassName} style={{ translateY }} ref={divRef}>
      <ImageCover {...{ image, aspectRatio }} imgProps={{ loading: 'lazy' }} />
    </animated.div>
  )
}
