import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useElementSize } from '@kaliber/use-element-size'
import styles from './BackgroundVideo.css'

export function BackgroundVideo({ sources, layoutClassName = undefined, playing }) {
  const videoRef = React.useRef(null)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { src, ref: qualityRef } = useVideoQualityLevel({ sources })

  React.useEffect(
    () => {
      if (isInViewport && playing) playSafe(videoRef.current)
      else videoRef.current.pause()
    },
    [isInViewport, playing]
  )

  return (
    <div ref={visibilityRef} className={cx(styles.component, layoutClassName)}>
      <div ref={qualityRef} className={styles.qualityWrapperElement}>
        <video
          className={styles.video}
          controlsList="nodownload"
          loop
          muted
          playsInline
          autoPlay={playing}
          preload="metadata"
          ref={videoRef}
          {...{ src }}
        />
      </div>
    </div>
  )
}

function useVideoQualityLevel({ sources }) {
  const [src, setSrc] = React.useState(null)
  const { ref, size: { width, height } } = useElementSize()

  React.useEffect(
    () => {
      const sourceEntriesAsc = Object.entries(sources).sort(([a], [b]) => Number(a) - Number(b))
      const [fallbackEntry] = sourceEntriesAsc

      if (!fallbackEntry) {
        setSrc(null)
        return
      }

      // @ts-ignore
      const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection

      if (connection?.saveData) {
        setSrc(fallbackEntry[1] ?? null)
        return
      }

      const connectionType = connection?.effectiveType ?? '4g'
      const maxLevel = {
        'slow-2g': 0,
        '2g': 0,
        '3g': 1,
        '4g': 3
      }[connectionType]

      const neededLevel = [
        ...sourceEntriesAsc,
        [Infinity, null]
      ].findIndex(([size]) => size >= Math.min(width, height))

      const [, source] = sourceEntriesAsc[Math.min(neededLevel, maxLevel)]
      setSrc(source)
    },
    [width, height, sources]
  )

  return { ref, src }
}

function playSafe(video) {
  return Promise.resolve(video.play()).catch(_ => {})
}
